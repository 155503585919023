<template>
    
    <v-container>

        <v-card 
        v-for="website in websitesData"
        :key="website.id"
        @click="set(website)"
        class="pa-4"
        >
            {{ website.data().name }}
        </v-card>

    </v-container>

</template>

<script>
    
import { inject, ref, onMounted } from '@vue/composition-api' ;
import websites from "@/store/websites/websites" ;

export default {
    
    provide: {
        websites
    },
    setup(props, ctx){
    
        const app = inject('app') ;
        const currentUser = inject('currentUser') ;
        const currentWebsite = inject('currentWebsite') ;

        const websitesData = ref([]) ;

        onMounted(() => {

            websites.get()
            .then((querySnapshot) => {

                querySnapshot.forEach((doc) => {
                    websitesData.value.push(doc);
                });

            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
                app.setError(error) ;
            });

            currentWebsite.setTitle(ctx.root.$t('Websites'));
            
        });
        

        const set = (website) => {

            currentWebsite.set(website) ;
            ctx.root.$router.push('/dashboard') ;

        } ;
        
        return {
        app,
        currentWebsite,
        currentUser,
        websitesData,
        set
        }
    
    },

};

</script>