import { reactive } from '@vue/composition-api' ;
import firebase from 'firebase' ;
import currentUser from "@/store/users/currentUser" ;

const state = reactive({
    data: null,
});

const get = () => {

    console.log('currentUser.uid()', currentUser.uid());
     
    return firebase.firestore().collection("websites")
    .where("users", "array-contains", currentUser.uid())
    .get() ;

}

export default { 
    state,
    get,
 } ;